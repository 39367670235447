import { Pipe, PipeTransform } from "@angular/core"
import IKeyValuePair from "../interfaces/IKeyValuePair";

@Pipe({ name: "KeyValuePair" })
export class KeyValuePairPipe implements PipeTransform {

    transform(value: any, args?: any) {
        return Object.keys(value).map(key => <IKeyValuePair>{
            key, value: value[key]
        })
    }

}

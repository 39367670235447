import { Directive, Input } from "@angular/core"

@Directive({
    selector: "[appInit]",
    exportAs: "[appInit]"
})
export class InitDirective {
    @Input()
    appInit: () => any

    constructor() {
        if(this.appInit)
            this.appInit()
    }
}

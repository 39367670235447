import { Component, HostListener, ElementRef } from "@angular/core"
import Task from "src/app/models/Task"
import { CommunicationService } from "src/app/services/com.service"
import OS from "src/app/models/OS"

@Component({
    selector: "app-os",
    templateUrl: "./os.component.html",
    styleUrls: ["./os.component.scss"]
})
export class OSComponent {
    model: OS = new OS()

    edit: OS = null

    constructor(public com: CommunicationService, public element: ElementRef) {}

    delete(task: Task) {
        this.com.osDelete$.next(task)
    }

    add() {
        this.com.osCreate$.next(this.model)
    }

    save(model: OS) {
        this.edit = null
        this.com.osUpdate$.next(model)
    }

    @HostListener("document:click", ["$event"])
    click($event) {
        if(!this.element.nativeElement.contains($event.target))
            this.edit = null
    }
}

import Model from "./Model"
import Machine from "./Machine";
import { AppService } from "../services/app.service";

export default class IP extends Model implements Listable {
    name = ""
    machine: Machine

    getLink() {
        if(this.name.match(/.*?:\/\/.*/))
            return this.name
    }
}
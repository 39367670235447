import { Component, OnInit, HostListener, OnDestroy } from "@angular/core"
import { AppService } from "../../services/app.service"
import config from "../../config"
import INavigationItem from "src/app/interfaces/INavigationItem";
import { CommunicationService } from "src/app/services/com.service";
import { Subscription } from "rxjs";
import { Toast } from "src/app/types/Toast";
import { Router } from "@angular/router";

@Component({
    selector: "app-nav",
    templateUrl: "./nav.component.html",
    styleUrls: ["./nav.component.scss"]
})
export class NavComponent implements OnInit, OnDestroy {
    rootItem: INavigationItem = config.navigation

    display = "block"

    private messageSubject: Subscription

    private toasts: Toast[] = []

    constructor(public app: AppService, public com: CommunicationService, public router: Router) {
        this.messageSubject = this.com.message$.subscribe(data => {
            const toast = new Toast(data.message, data.color, data.timeout || 5000)
            this.toasts.push(toast)
            setTimeout(() => this.clearToast(toast), toast.timeout)
        })
    }

    @HostListener("window:resize")
    ngOnInit() {
        if (!this.app.mobile)
            this.display = "block"
        else if (this.display === "block")
            this.display = "none"
    }

    toggleDisplay() {
        this.display = this.display === "grid" || this.display === "block" ? "none" : "grid"
    }

    clearToast(toast: Toast) {
        this.toasts.splice(this.toasts.indexOf(toast), 1)
    }

    ngOnDestroy() {
        this.messageSubject.unsubscribe()
    }

    logout(): void {
        this.app.userdata = null
        localStorage.removeItem("kec-key")
        this.router.navigateByUrl("/auth")
    }
}
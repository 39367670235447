import { Component, OnDestroy } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { CommunicationService } from "src/app/services/com.service"
import Customer from "src/app/models/Customer"
import Machine from "src/app/models/Machine"
import Task from "src/app/models/Task"
import { FormComponent } from "../_form.component";
import IP from "src/app/models/IP";
import { Preference } from "src/app/models/Preference";
import OS from "src/app/models/OS";
import { AppService } from "src/app/services/app.service"

@Component({
    selector: "app-customer.edit",
    templateUrl: "./customer.edit.component.html",
    styleUrls: ["./customer.edit.component.scss"]
})
export class CustomerEditComponent extends FormComponent implements OnDestroy {
    machine = new Machine()

    data = null
    private dataSubscription = this.com.customerEdit$.subscribe(data => {
        if(data.model.id == this.route.snapshot.params.id) {
            this.data = data

            this.machine.customer = data.model

            this.data.model.machines.map(machine => {
                machine.ips = machine.ips.map(ip => {
                    const model = new IP(ip)
                    const [host, protocol] = ip.name.split("//").reverse()
                    model.name = (protocol ? protocol.replace(/\s*/g, "") + "//" : "") + host.replace(/\s*/g, "")
                    return model
                })
            })
        }
    })

    constructor(public app: AppService, public route: ActivatedRoute, public com: CommunicationService) {
        super()
    }

    track(index: number) {
        return index
    }

    hasUnsavedData(): boolean {
        return false
    }

    ngOnDestroy() {
        this.dataSubscription.unsubscribe()
    }

    update() {
        this.com.customerUpdate$.next(this.data.model)
    }

    updateMachine(machine: Machine) {
        if(machine !== this.machine) {
            machine.customer = new Customer()
            machine.customer.id = this.route.snapshot.params.id
            this.com.machineUpdate$.next(machine)
        }
    }

    log(x) {
        console.log(x)
    }

    updatePreference(preference: Preference) {
        this.com.preferenceUpdate$.next(preference)
    }

    addMachine() {
        this.com.machineCreate$.next(this.machine)
        this.machine = new Machine(this.data.model)
    }

    removeMachine(machine: Machine) {
        machine.deleted = new Date()
        machine.customer = new Customer()
        machine.customer.id = this.route.snapshot.params.id
        this.com.machineUpdate$.next(machine)
    }

    addOS(machine: Machine, name: string) {
        const os = new OS()
        os.name = name
        machine.customer = new Customer()
        machine.customer.id = this.route.snapshot.params.id
        os.machines.push(machine)

        this.com.osCreate$.next(os)

        this.machine.os = { ...os }
        this.machine.os.machines = []
    }

    addTask(machine: Machine, name: string) {
        const task = new Task()
        task.name = name
        if (machine !== this.machine) {
            machine.customer = new Customer()
            machine.customer.id = this.route.snapshot.params.id
            task.machines.push(machine)
        }
        this.com.taskCreate$.next(task)

        if(machine === this.machine)
            this.machine.tasks.push(task)
    }

    addIP(machine: Machine, ip: string) {
        const model = new IP()
        model.name = ip
        if(machine === this.machine)
            this.machine.ips.push(model)
        else {
            model.machine = machine
            this.com.ipCreate$.next(model)
        }
    }

    removeIP(machine: Machine, ip: IP) {
        ip.machine = machine
        this.com.ipRemove$.next(ip)
    }
}

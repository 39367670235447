import { Injectable, } from "@angular/core"
import { WebsocketService } from "./websocket.service"

@Injectable({
    providedIn: "root"
})
export class CommunicationService {

    activityHistory$ = this.ws.on("activity:history")
    activityView$ = this.ws.on("activity:view")
    activityEdit$ = this.ws.on("activity:edit")
    activitySync$ = this.ws.on("activity:sync")
    activitySend$ = this.ws.on("activity:send")
    activityUpdate$ = this.ws.on("activity:update")

    customerView$ = this.ws.on("customer:view")
    customerCreate$ = this.ws.on("customer:create")
    customerEdit$ = this.ws.on("customer:edit")
    customerUpdate$ = this.ws.on("customer:update")

    positionCreate$ = this.ws.on("position:create")
    positionUpdate$ = this.ws.on("position:update")

    machineCreate$ = this.ws.on("machine:create")
    machineUpdate$ = this.ws.on("machine:update")

    taskView$ = this.ws.on("task:view")
    taskCreate$ = this.ws.on("task:create")
    taskUpdate$ = this.ws.on("task:update")
    taskDelete$ = this.ws.on("task:delete")

    deploymentView$ = this.ws.on("deployment:view")
    deploymentCreate$ = this.ws.on("deployment:create")
    deploymentUpdate$ = this.ws.on("deployment:update")
    deploymentDelete$ = this.ws.on("deployment:delete")

    osView$ = this.ws.on("os:view")
    osCreate$ = this.ws.on("os:create")
    osUpdate$ = this.ws.on("os:update")
    osDelete$ = this.ws.on("os:delete")

    ipCreate$ = this.ws.on("ip:create")
    ipRemove$ = this.ws.on("ip:delete")

    calendarEdit$ = this.ws.on("calendar:edit")
    calendarPlan$ = this.ws.on("calendar:plan")
    calendarCancel$ = this.ws.on("calendar:cancel")
    calendarSave$ = this.ws.on("calendar:save")

    message$ = this.ws.on("message")

    auth$ = this.ws.on("auth")
    authCredentials$ = this.ws.on("auth:credentials")
    authKey$ = this.ws.on("auth:key")
    authInvalid$ = this.ws.on("auth:invalid")

    preferenceUpdate$ = this.ws.on("preference:update")

    constructor(public ws: WebsocketService) {}
}
export class Base64 {
    static keys = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/="

    static encode(input: string) {
        input = Base64.utf8_encode(input)
        let result = ""
        for (let i = 0; i < input.length;) {
            const array: number[] = []

            for (let c = 0; c < 3; c++)
                array.push(input.charCodeAt(i++))

            array.push(array[0] >> 2)
            array.push((array[0] & 3) << 4 | array[1] >> 4)
            array.push((array[1] & 15) << 2 | array[2] >> 6)
            array.push(array[2] & 63)

            if (isNaN(array[1]))
                array[5] = array[6] = 64
            else if (isNaN(array[2]))
                array[6] = 64

            result += this.keys.charAt(array[3]) + this.keys.charAt(array[4]) + this.keys.charAt(array[5]) + this.keys.charAt(array[6])
        }

        return result
    }

    static decode(input: string) {
        input = input.replace(/[^A-Za-z0-9\+\/\=]/g, "")
        let result = ""
        for (let i = 0; i < input.length;) {
            const array: number[] = []

            for (let c = 0; c < 4; c++)
                array.push(this.keys.indexOf(input.charAt(i++)))
            array.push(array[0] << 2 | array[1] >> 4)
            array.push((array[1] & 15) << 4 | array[2] >> 2)
            array.push((array[2] & 3) << 6 | array[3])
            result += String.fromCharCode(array[4])
            if (array[2] !== 64)
                result += String.fromCharCode(array[5])
            if (array[3] !== 64)
                result += String.fromCharCode(array[6])
        }

        return Base64.utf8_decode(result)
    }
    static utf8_encode(input: string) {
        input = input.replace(/\r\n/g, "\n")
        let result = ""
        for (let i = 0; i < input.length; i++) {
            const tmp = input.charCodeAt(i)
            if (tmp < 128)
                result += String.fromCharCode(tmp)
            else if (tmp > 127 && tmp < 2048)
                result += String.fromCharCode(tmp >> 6 | 192) + String.fromCharCode(tmp & 63 | 128)
            else
                result += String.fromCharCode(tmp >> 12 | 224) + String.fromCharCode(tmp >> 6 & 63 | 128) + String.fromCharCode(tmp & 63 | 128)
        }
        return result
    }
    static utf8_decode(input: string) {
        let result = ""
        for (let i = 0; i < input.length;) {
            const array: number[] = []
            array.push(input.charCodeAt(i))
            array.push(input.charCodeAt(i + 1))
            array.push(input.charCodeAt(i + 2))

            if (array[0] < 128) {
                result += String.fromCharCode(array[0])
                i++
            } else if (array[0] > 191 && array[0] < 224) {
                result += String.fromCharCode((array[0] & 31) << 6 | array[1] & 63)
                i += 2
            } else {
                result += String.fromCharCode((array[0] & 15) << 12 | (array[1] & 63) << 6 | array[2] & 63)
                i += 3
            }
        }
        return result
    }
}
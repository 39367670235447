import { HostListener } from "@angular/core";

export abstract class FormComponent {
    abstract hasUnsavedData(): boolean

    @HostListener("window:beforeunload", ["$event"])
    unloadNotification($event: any) {
        if(this.hasUnsavedData()) {
            $event.returnValue = true;
        }
    }
}
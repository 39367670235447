import { Component, OnDestroy } from "@angular/core"
import { Router, ActivatedRoute, Params } from "@angular/router"
import { CommunicationService } from "src/app/services/com.service"
import { Subscription } from "rxjs"
import { CdkDragDrop } from "@angular/cdk/drag-drop"
import Activity from "src/app/models/Activity";
import { AppService } from "src/app/services/app.service";
import { FormComponent } from "../_form.component";

@Component({
    selector: "app-calendar",
    templateUrl: "./calendar.component.html",
    styleUrls: ["./calendar.component.scss"]
})
export class CalendarComponent extends FormComponent implements OnDestroy {
    calendar: CalendarDate[] = []

    calendarSubscription: Subscription
    paramSubscription: Subscription = this.route.params.subscribe(params => this.selected = {
        month: parseInt(params.month),
        year: parseInt(params.year),
        date: new Date(parseInt(params.year), parseInt(params.month) - 1)
    })

    lists: string[] = []

    readonly daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]

    selected: { month: number, year: number, date: Date }
    current: { month: number, year: number, date: Date }

    constructor(public app: AppService, public com: CommunicationService, public router: Router, public route: ActivatedRoute) {
        super()

        const current = new Date()
        this.current = { month: current.getMonth() + 1, year: current.getFullYear(), date: current }

        this.calendarSubscription = this.com.calendarEdit$.subscribe(data => {
            this.planning = data.planning || false
            const date = new Date(this.selected.year, this.selected.month - 1, 1)
            if(date.getFullYear() === this.selected.year && date.getMonth() + 1 <= this.selected.month) {
                this.calendar = []
                let cacheDay: number
                let day = -date.getDay() + 1
                do {
                    date.setMonth(this.selected.month - 1, ++day)

                    const cacheYear = date.getFullYear()
                    if (cacheYear > this.selected.year + 1)
                        date.setFullYear(this.selected.year + 1)

                    this.calendar.push({ data: [], date: {
                        dayOfWeek: date.getDay(),
                        dayOfMonth: date.getDate(),
                        month: date.getMonth() + 1
                    }})

                    cacheDay = date.getDay()
                    if(cacheYear < this.selected.year && date.getDate() > 1) // be careful when changing this
                        date.setFullYear(this.selected.year)
                } while (date.getMonth() <= this.selected.month - 1 && date.getFullYear() <= this.selected.year || cacheDay !== 0);
                
                data.calendar.map((e: CalendarEvent) => {
                    const date = new Date(e.date)
                    delete e.date
                    const element = this.calendar.filter(x => x.date.month === date.getUTCMonth() + 1 && x.date.dayOfMonth === date.getUTCDate())[0]

                    if(element) {
                        e.style = { color: this.app.getForegroundColor(e.customer.color), background: e.customer.color, border: e.unplanned ? "1px solid red" : "none" }

                        element.data = [...element.data || [], e]
                    }
                })
            } else 
                this.router.navigate([`/calendar/edit/${date.getFullYear()}/${(date.getMonth() + 1)}`])
        })
    }

    planning = false

    plan() {
        this.com.calendarPlan$.next({ month: this.selected.month, year: this.selected.year })
    }

    savePlanning() {
        this.com.calendarSave$.next({ month: this.selected.month, year: this.selected.year, calendar: this.calendar })
    }

    cancelPlanning() {
        this.com.calendarCancel$.next({ month: this.selected.month, year: this.selected.year })
    }

    validDate(calendar: CalendarDate): boolean {
        return calendar.date.month === this.selected.month && (this.selected.month > this.current.month || this.selected.month === this.current.month && calendar.date.dayOfMonth >= this.current.date.getDate()) && [0].indexOf(calendar.date.dayOfWeek) === -1
    }

    initList(calendar: CalendarDate) {
        const validDate = this.validDate(calendar)
        if(validDate)
            this.lists.push(`cdk-list-${calendar.date.dayOfMonth}`)

        return validDate
    }

    hasUnsavedData(): boolean {
        return this.planning
    }

    select(activity: Activity) {
        this.router.navigate([`/activity/edit/${activity.id}`])
    }

    drop(event: CdkDragDrop<string[]>) {
        if(event.previousContainer !== event.container) {
            let reason: any = true

            if(!this.planning)
                reason = prompt("Grund eingeben")

            if(reason) {
                event.previousContainer.data.splice(event.previousContainer.data.indexOf(event.item.data), 1)
                event.container.data.push(event.item.data)

                event.item.data.date = new Date(Date.UTC(this.route.snapshot.params.year, this.route.snapshot.params.month - 1, parseInt(event.container.id.split("-").slice(-1)[0])))

                if(!this.planning)
                    this.com.activityUpdate$.next({reason, ...event.item.data})
            }
        }
    }

    ngOnDestroy() {
        this.cancelPlanning()
        this.calendarSubscription.unsubscribe()
        this.paramSubscription.unsubscribe()
    }
}
import { BrowserModule } from "@angular/platform-browser"
import { BrowserAnimationsModule } from "@angular/platform-browser/animations"
import { NgModule, LOCALE_ID } from "@angular/core"
import { DragDropModule } from "@angular/cdk/drag-drop"

import { AppService } from "./services/app.service"

import { AppRoutingModule } from "./app-routing.module"

import { FormsModule } from "@angular/forms"

import { AppComponent } from "./components/app/app.component"
import { NavComponent } from "./components/nav/nav.component"
import { MenuComponent } from "./components/menu/menu.component"
import { KeyValuePairPipe } from "./pipes/KeyValuePair.pipe"
import { HttpClientModule } from "@angular/common/http"
import { ActivityComponent } from "./components/activity/activity.component"
import { ActivityEditComponent } from "./components/activity.edit/activity.edit.component"
import { CustomerComponent } from "./components/customer/customer.component"
import { InitDirective } from "./directives/init.directive"
import { VarableDirective } from "./directives/variable.directive"
import { PositionPipe } from "./pipes/position.pipe"
import { CustomerEditComponent } from "./components/customer.edit/customer.edit.component"
import { ToggleComponent } from "./components/toggle/toggle.component"
import { ListComponent } from "./components/list/list.component"
import { CalendarComponent } from "./components/calendar/calendar.component"

import { registerLocaleData } from "@angular/common"
import locale from "@angular/common/locales/de"
import { ListPipe } from "./pipes/list.pipe";
import { DebounceDirective } from "./directives/debounce.directive"
import { SafePipe } from "./pipes/safe.pipe"
import { AuthComponent } from "./components/auth/auth.component"
import { SplashComponent } from "./components/splash/splash.component"
import { TaskComponent } from "./components/task/task.component"
import { DeploymentComponent } from "./components/deployment/deployment.component";
import { OSComponent } from "./components/os/os.component";
import { AutoFocusDirective } from "./directives/auto-focus.directive";
import { TextComponent } from './components/text/text.component'

@NgModule({
    declarations: [
        AppComponent,
        NavComponent,
        KeyValuePairPipe,
        SafePipe,
        MenuComponent,
        ActivityComponent,
        ActivityEditComponent,
        CustomerComponent,
        InitDirective,
        VarableDirective,
        PositionPipe,
        CustomerEditComponent,
        ToggleComponent,
        ListComponent,
        ListPipe,
        CalendarComponent,
        DebounceDirective,
        AuthComponent,
        SplashComponent,
        TaskComponent,
        DeploymentComponent,
        OSComponent,
        AutoFocusDirective,
        TextComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        HttpClientModule,
        DragDropModule,
        FormsModule
    ],
    providers: [ AppService, { provide: LOCALE_ID, useValue: "de"} ],
    bootstrap: [ AppComponent ]
})
export class AppModule { }

registerLocaleData(locale)
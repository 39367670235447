import { Directive, Input, AfterContentInit, ElementRef } from "@angular/core"

@Directive({
    selector: "[appAutoFocus]"
})
export class AutoFocusDirective implements AfterContentInit {
    @Input() public appAutoFocus = true

    constructor(public element: ElementRef) { }

    ngAfterContentInit() {
        this.element.nativeElement.focus()
    }
}

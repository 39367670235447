import { Component } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { CommunicationService } from "src/app/services/com.service"
import Position from "src/app/models/Position"
import { WebsocketService } from "src/app/services/websocket.service"
import Machine from "src/app/models/Machine";
import Activity from "src/app/models/Activity";
import { AppService } from "src/app/services/app.service"

@Component({
    selector: "app-activity-edit",
    templateUrl: "./activity.edit.component.html",
    styleUrls: ["./activity.edit.component.scss"]
})
export class ActivityEditComponent {
    newVisible: { [key: number]: boolean } = {}

    data = null
    private dataSubscription = this.com.activityEdit$.subscribe(data => {
        if(data.model.id == this.route.snapshot.params.id) {
            this.data = data
            for(let machine of data.model.customer.machines) {
                for(let ip of machine.ips) {
                    const [host, protocol] = ip.name.split("//").reverse()
                    ip.name = (protocol ? protocol.replace(/\s*/g, "") + "//" : "") + host.replace(/\s*/g, "")
                }
            }
        }
    })
    ngOnDestroy() {
        this.dataSubscription.unsubscribe()
    }

    constructor(public app: AppService, public route: ActivatedRoute, public com: CommunicationService, public ws: WebsocketService) {}

    sendmail() {
        this.com.activitySend$.next(this.data.model)
    } 

    togglePositionDone(position: Position) {
        position.done = !position.done
        this.ws.sender("position:update", position)
    }

    toggleDone(locked: boolean) {
        this.com.activityUpdate$.next({ id: this.route.snapshot.params.id, done: locked })
    }

    updatePosition(position: Position) {
        this.com.positionUpdate$.next(position)
    }

    track(index: any, item: any) {
        return index;
    }

    checkEmpty(machine: Machine, data: any) {
        return data.model.positions.filter(x => x.machine.id === machine.id).length === 0
    }

    checkLink(name: string) {
        return name.match(/.*?:\/\/.*/)
    }
    
    syncActivity(activity: Activity) {
        this.com.activitySync$.next(activity)
    }

    log(x) {
        console.log(x)
    }
 
    change(model, value) {
        model.date = new Date(model.date)
        let [oldHours, oldMinutes] = [model.date.getUTCHours(), model.date.getUTCMinutes()]
        let [hours, minutes] = value.ngModel.split(":").map(x => parseInt(x))
        if(!isNaN(hours) && !isNaN(minutes)) {
            const [year, month, date] = [model.date.getYear(), model.date.getMonth(), model.date.getDate()]
            model.date.setUTCHours(hours, minutes)
            model.date.setYear(1900 + year)
            model.date.setMonth(month, date)
            this.com.activityUpdate$.next(model)
            value.ngModel = (hours < 10 ? "0" : "") + hours + ":" + (minutes < 10 ? "0" : "") + minutes
        } else {
            value.ngModel = (oldHours < 10 ? "0" : "") + oldHours + ":" + (oldMinutes < 10 ? "0" : "") + oldMinutes
        }
    }

    save(model) {
        this.com.activityUpdate$.next(model)
    }

    editors(data) {
        return data.model.activityUsers.map(activityUser => activityUser.user.name).join(', ')
    }
}
import { Directive, Output, EventEmitter, Renderer2, ElementRef, OnDestroy, Input } from "@angular/core"
import { fromEvent, Subscription, merge } from "rxjs"

import { debounceTime, map, distinctUntilChanged } from "rxjs/operators"
import { NgModel } from "@angular/forms";

@Directive({
    selector: "[appDebounce]"
})
export class DebounceDirective implements OnDestroy {

    @Output()
    appDebounce: EventEmitter<any>

    @Input()
    ngModel: NgModel

    private changeSubscription: Subscription
    private validSubscription: Subscription

    private ignore = ["Tab"]

    constructor(elementRef: ElementRef, renderer: Renderer2) {
        this.appDebounce = <EventEmitter<any>>merge(
            fromEvent(elementRef.nativeElement, "keyup"),
            fromEvent(elementRef.nativeElement, "focusout")
        ).pipe(map(i => (<any>i).currentTarget.value), debounceTime(750), distinctUntilChanged())

        this.changeSubscription = fromEvent(elementRef.nativeElement, "keyup").subscribe((key) => {
            if(this.ignore.indexOf((<KeyboardEvent>key).key) === -1)
                renderer.addClass(elementRef.nativeElement, "appDirty")
        })

        this.validSubscription = this.appDebounce.subscribe(() => {
            renderer.removeClass(elementRef.nativeElement, "appDirty")
        })
    }

    ngOnDestroy() {
        this.validSubscription.unsubscribe()
        this.changeSubscription.unsubscribe()
    }
}

import { Component, Input, Output, EventEmitter, HostListener, ElementRef } from "@angular/core"

import { DomSanitizer } from "@angular/platform-browser"

@Component({
    selector: "app-list",
    templateUrl: "./list.component.html",
    styleUrls: ["./list.component.scss"]
})
export class ListComponent {
    @Output()
    learn = new EventEmitter()

    @Input()
    multiple = false

    @Input()
    model: Listable|Listable[]

    @Output()
    modelChange = new EventEmitter()

    @Output()
    appChange = new EventEmitter()

    @Output()
    appRemove = new EventEmitter()

    @Input()
    list: Listable[]

    @Input()
    placeholder = "..."

    input = ""
    focus = false

    constructor(public eRef: ElementRef, public sanitization: DomSanitizer) {}

    listable(variable: Listable|Listable[]): Listable {
        return <Listable>variable
    }

    add() {
        if(this.input.length) {
            if(this.list) {
                const filtered = this.list.filter(x => x.name.toLowerCase() === this.input.toLowerCase())
                if(filtered.length && (!this.multiple || (<Listable[]>this.model).indexOf(filtered[0])))
                    this.select(null, filtered[0])
                else
                    this.learn.emit(this.input)
            } else
                this.learn.emit(this.input)

            this.input = ""
            this.focus = false
        } else {
            this.focus = true
        }
    }
    
    select($event: Event, element: Listable | Listable[]) {
        if(!this.list)
            return

        if($event) {
            $event.preventDefault()
            $event.stopPropagation()
        }

        if(this.multiple)
            (<Listable[]>this.model).push(<Listable>element)
        else {
            this.model = <Listable>element
            this.focus = false
            this.input = ""
        }

        this.modelChange.emit(this.model)
        this.appChange.emit(this.model)

        this.list = [...this.list] // yup, this is really necessary o~o
    }

    @HostListener("document:click", ["$event"])
    click($event) {
        let element = this.eRef.nativeElement
        if(this.multiple)
            element = element.children[1]

        if(!element.contains($event.target))
            this.focus = false
    }

    remove(element: Listable) {
        let data = null
        if(this.multiple) {
            const model = <Listable[]>this.model
            const index = model.indexOf(element)
            data = model.splice(index, 1)[0]
        } else {
            this.model = null
            this.focus = false
        }
        this.modelChange.emit(this.model)
        this.appRemove.emit(data)
        
        if(this.list)
            this.list = [...this.list]
    }
}

import { Component, HostListener, OnInit, OnDestroy } from "@angular/core"
import { AppService } from "../../services/app.service"

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit {

    constructor(public app: AppService) {}

    @HostListener("window:resize")
    ngOnInit() {
        this.app.mobile = innerWidth <= 800
    }
}

import { Component, HostListener, ElementRef } from "@angular/core"
import Task from "src/app/models/Task";
import { CommunicationService } from "src/app/services/com.service";

@Component({
    selector: "app-task",
    templateUrl: "./task.component.html",
    styleUrls: ["./task.component.scss"]
})
export class TaskComponent {
    model: Task = new Task()
    edit: Task = null

    hover: boolean = false

    constructor(public com: CommunicationService, public element: ElementRef) {}

    delete(model: Task) {
        this.com.taskDelete$.next(model)
    }

    add() {
        this.com.taskCreate$.next(this.model)
    }

    save(model: Task) {
        this.edit = null
        this.com.taskUpdate$.next(model)
    }

    @HostListener("document:mousedown", ["$event"])
    click($event) {
        if (!this.element.nativeElement.contains($event.target))
            this.edit = null
    }
}

import { Component, OnInit, Input, HostListener } from "@angular/core"
import { AppService } from "../../services/app.service"
import INavigationItem from "../../interfaces/INavigationItem"

@Component({
    selector: "[app-menu]",
    templateUrl: "./menu.component.html",
    styleUrls: ["./menu.component.scss"]
})
export class MenuComponent implements OnInit {

    @Input()
    item: INavigationItem

    visible = false

    constructor(public app: AppService) {}

    @HostListener("window:resize")
    @HostListener("mouseleave")
    ngOnInit() {
        if(!this.app.mobile)
            this.visible = false
    }

    @HostListener("click", ["$event"])
    onClick(e) {
        e.stopPropagation()
        if(this.app.mobile)
            this.visible = !this.visible
    }

    @HostListener("mouseenter")
    onHover() {
        if(!this.app.mobile)
            this.visible = true
    }
}

import { Injectable } from "@angular/core"
import { CommunicationService } from "./com.service"
import { Router } from "@angular/router"
import { Subscription } from "rxjs";
import { Base64 } from "../types/Base64";

@Injectable()
export class AppService {
    public mobile: boolean

    public loading = false

    public dirty = false

    public cachedRoute: string|null = null

    public userdata: any = null

    public backToHistory: number = null

    login(name: string, password: string): void {
        this.com.authCredentials$.next({ name, password })
    }

    constructor(public com: CommunicationService, private router: Router) {
        this.com.authInvalid$.subscribe(() => {
            localStorage.removeItem("kec-key")
            this.router.navigate(["/auth"])
        })

        const key = localStorage.getItem("kec-key")
        if(key) {
            new Promise(resolve => {
                this.com.authKey$.next({ key })
                let authSubscription: Subscription
                function handle(data) {
                    if (authSubscription) {
                        authSubscription.unsubscribe()
                        resolve(data)
                    }
                }
                authSubscription = this.com.auth$.subscribe(handle)
                setTimeout(handle, 5000)
            }).then((data: any) => {
                if(!data.message)
                    this.userdata = JSON.parse(Base64.decode(key))
                else
                    localStorage.removeItem("kec-key")
            })
        }
    }

    getForegroundColor(r: number | string, g: number = null, b: number = null) {
        if (!g && !b) {
            const hex = parseInt((<string>r).replace("#", ""), 16);
            [r, g, b] = [(hex >> 16) & 255, (hex >> 8) & 255, hex & 255]
        }
        return (0.299 * <number>r + 0.587 * g + 0.114 * b) / 255 > 0.5 ? "#000000" : "#ffffff"
    }
}
import Task from "./Task"
import Model from "./Model"
import Customer from "./Customer"
import IP from "./IP"
import OS from "./OS";
import { Preference } from "./Preference";

export default class Machine extends Model implements Listable {
    name = ""
    deployment = ""
    description = ""

    tasks: Task[] = []
    deleted: Date
    type: "Server" | "Workspace" = "Server"

    ips: IP[] = []

    os: OS = null

    constructor(public customer: Customer = null) {
        super()
    }
}
import INavigationItem from "./interfaces/INavigationItem";

export default {
    _: "",
    backend: "develsrv:8080",
    env: "prod", // dev
    navigation: <INavigationItem>{
        children: [
            { label: "Dashboard", icon: "fas fa-home", routerLink: "/activity/view" },
            { label: "Customers", routerLink: "/customer/view", icon: "fas fa-user-tie" },
            { label: "Calendar", routerLink: "/calendar", icon: "fas fa-calendar" },
            { label: "More", icon: "fas fa-bookmark", children: [
                { label: "Tasks", routerLink: "/task/view", icon: "fas fa-tasks" },
                { label: "OS", routerLink: "/os/view", icon: "fab fa-windows" },
                { label: "Deployments", routerLink: "/deployment/view", icon: "fas fa-server" },
            ]},
            /*{ label: "Admins", routerLink: "/admin/view", icon: "fas fa-users" },
            {
                label: "Dropdown", children: [
                    { label: "Meh", routerLink: "/meh", icon: "fas fa-users" },
                    { label: "Another", routerLink: "/another" },
                    {
                        label: "Second level",
                        children: [
                            { label: "Something", routerLink: "/something" },
                            { label: "Something else", routerLink: "/somethingelse" },
                            {
                                label: "Final", children: [
                                    { label: "da shit", routerLink: "/dashit" }
                                ]
                            }
                        ]
                    }
                ]
            },*/
        ]
    }
}

import { Injectable } from "@angular/core"
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router"
import { Observable } from "rxjs"
import { AppService } from "../services/app.service";

@Injectable({ providedIn: "root" })
export class AuthGuard implements CanActivate {
    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if(!this.app.userdata)
            this.router.navigateByUrl("/auth")
        this.app.cachedRoute = state.url
        return !!this.app.userdata
    }

    constructor(public app: AppService, public router: Router) {}
}

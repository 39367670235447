import { Component } from "@angular/core"
import { CommunicationService } from "src/app/services/com.service";
import { ActivatedRoute } from "@angular/router";
import { AppService } from "src/app/services/app.service";


@Component({
    selector: "app-activity",
    templateUrl: "./activity.component.html",
    styleUrls: ["./activity.component.scss"]
})
export class ActivityComponent {
    constructor(private app: AppService, public com: CommunicationService, private route: ActivatedRoute) {
        this.app.backToHistory = this.route.snapshot.params.id ? parseInt(this.route.snapshot.params.id) : null
    }

    log(x) {
        console.log(x)
    }
}

import { Component } from "@angular/core"
import { Router } from "@angular/router"
import { CommunicationService } from "src/app/services/com.service"
import Customer from "src/app/models/Customer"

@Component({
    selector: "app-customer",
    templateUrl: "./customer.component.html",
    styleUrls: ["./customer.component.scss"]
})
export class CustomerComponent {
    customer = new Customer()

    constructor(public com: CommunicationService, private router: Router) {}

    add() {
        this.com.customerCreate$.next(this.customer)
        // this.router.navigate(["/customer"]) redirect after creation?
    }

    delete(customer: Customer) {
        customer.deleted = new Date()
        this.com.customerUpdate$.next(customer)
    }
}
import { Directive, Input } from "@angular/core"

@Directive({
    selector: "[appVar]",
    exportAs: "var"
})
export class VarableDirective {
    @Input()
    appVar: any
}

import { NgModule } from "@angular/core"
import { Routes, RouterModule } from "@angular/router"
import { ActivityComponent } from "./components/activity/activity.component"
import { ActivityEditComponent } from "./components/activity.edit/activity.edit.component"
import { CustomerComponent } from "./components/customer/customer.component"
import { CustomerEditComponent } from "./components/customer.edit/customer.edit.component"
import { CalendarComponent } from "./components/calendar/calendar.component"

import { FormGuard } from "./guards/form.guard"
import { AuthGuard } from "./guards/auth.guard"
import { GuestGuard } from "./guards/guest.guard"
import { SplashComponent } from "./components/splash/splash.component"
import { TaskComponent } from "./components/task/task.component"
import { DeploymentComponent } from "./components/deployment/deployment.component"
import { OSComponent } from "./components/os/os.component"
import { CalendarGuard } from "./calendar.guard"

const routes: Routes = [
    { path: "", redirectTo: "auth", pathMatch: "full" },
    { path: "auth", component: SplashComponent, pathMatch: "prefix", canActivate: [GuestGuard] },
    { path: "activity", children: [
        { path: "", redirectTo: "view", pathMatch: "prefix" },
        { path: "view", component: ActivityComponent, canActivate: [AuthGuard] },
        { path: "history/:id", component: ActivityComponent, canActivate: [AuthGuard] },
        { path: "edit/:id", component: ActivityEditComponent, canActivate: [AuthGuard] }
    ]},
    { path: "customer", children: [
        { path: "", redirectTo: "view", pathMatch: "prefix" },
        { path: "create", component: CustomerEditComponent, canActivate: [AuthGuard] },
        { path: "edit/:id", component: CustomerEditComponent, canActivate: [AuthGuard] },
        { path: "view", component: CustomerComponent, canActivate: [AuthGuard] },
    ]},
    { path: "calendar", children: [
        { path: "", children: [], canActivate: [AuthGuard, CalendarGuard], pathMatch: "prefix" },
        { path: "view", children: [], canActivate: [AuthGuard, CalendarGuard], pathMatch: "prefix" },
        { path: "edit/:year/:month", component: CalendarComponent, canActivate: [AuthGuard], canDeactivate: [FormGuard] }
    ]},
    { path: "task", children: [
        { path: "", redirectTo: "view", pathMatch: "prefix" },
        { path: "view", component: TaskComponent, canActivate: [AuthGuard] },
    ]},
    { path: "deployment", children: [
        { path: "", redirectTo: "view", pathMatch: "prefix" },
        { path: "view", component: DeploymentComponent, canActivate: [AuthGuard] },
    ]},
    { path: "os", children: [
        { path: "", redirectTo: "view", pathMatch: "prefix" },
        { path: "view", component: OSComponent, canActivate: [AuthGuard] },
    ]},
]

console.log(routes)

@NgModule({
    imports: [ RouterModule.forRoot(routes) ],
    exports: [ RouterModule ]
})
export class AppRoutingModule { }
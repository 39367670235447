import { Component, OnDestroy } from "@angular/core"
import { AppService } from "../../services/app.service"
import { NgForm } from "@angular/forms";
import { Subscription } from "rxjs";
import { Router } from "@angular/router";
import { CommunicationService } from "../../services/com.service";
import { Base64 } from "src/app/types/Base64";

@Component({
    selector: "app-auth",
    templateUrl: "./auth.component.html",
    styleUrls: ["./auth.component.scss"]
})
export class AuthComponent implements OnDestroy {
    error: string

    authSubscription: Subscription = this.com.auth$.subscribe(data => {
        if(!data.message) {
            const expire = new Date()
            expire.setFullYear(expire.getFullYear() + 1)
            localStorage.setItem("kec-key", data.key)
            this.app.userdata = JSON.parse(Base64.decode(data.key))
            this.router.navigateByUrl(this.app.cachedRoute || "/activity")
        } else {
            this.error = <string>data.message
        }
    })

    constructor(public app: AppService, public router: Router, public com: CommunicationService) {}

    attempt($event: Event, loginForm: NgForm) {
        $event.preventDefault()
        this.app.login(loginForm.value.name, loginForm.value.password)
    }
    ngOnDestroy() {
        this.authSubscription.unsubscribe()
    }
}

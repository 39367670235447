import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

@Component({
  selector: 'app-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss']
})
export class TextComponent implements OnInit {

    @Input()
    multiline = false

    @Input()
    placeholder = ""
    
    @Input()
    style = ""

    ngModel: any

    @Output()
    modelChange = new EventEmitter()

    @Input()
    get model() {
        return this.ngModel || ""
    }

    set model(value) {
        this.ngModel = value
        this.modelChange.emit(this.ngModel)
    }

    initial: any

    @Output()
    save = new EventEmitter()

    ngOnInit() {
        this.resetInitial()
    }

    constructor() { 
    }

    private resetInitial() {
        this.initial = JSON.parse(JSON.stringify(this.model))
    }

    saveButton() {
        this.resetInitial()
        this.save.emit(this.model)
    }

    resetButton() {
        this.model = JSON.parse(JSON.stringify(this.initial))
    }

}
import { Pipe, PipeTransform } from "@angular/core"

@Pipe({
    name: "list"
})
export class ListPipe implements PipeTransform {
    transform(value: Array<any>, args?: [string, Listable|Listable[], boolean]): any {
        const [input, model, multiple] = args

        // filter based on selection
        if(multiple)
            value = value.filter(x => (<Listable[]>model).map(x => x.name).indexOf(x.name) === -1)
        else if(model)
            value = value.filter(x => (<Listable>model).name.indexOf(x.name) === -1)

        // filter based on input
        if(input)
            value = value.filter(x => x.name.toLowerCase().indexOf(args[0].toLowerCase()) !== -1)

        return value
    }
}
import { Component, HostListener, ElementRef } from "@angular/core"
import Task from "src/app/models/Task";
import { CommunicationService } from "src/app/services/com.service";
import Deployment from "src/app/models/Deployment";

@Component({
    selector: "app-deployment",
    templateUrl: "./deployment.component.html",
    styleUrls: ["./deployment.component.scss"]
})
export class DeploymentComponent {
    model: Deployment = new Deployment()
    edit: Deployment = null

    constructor(public com: CommunicationService, public element: ElementRef) {}

    delete(model: Deployment) {
        this.com.deploymentDelete$.next(model)
    }

    add() {
        this.com.deploymentCreate$.next(this.model)
    }

    save(model: Deployment) {
        this.edit = null
        this.com.deploymentUpdate$.next(model)
    }

    @HostListener("document:click", ["$event"])
    click($event) {
        if (!this.element.nativeElement.contains($event.target))
            this.edit = null
    }
}

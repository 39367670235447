import { Pipe, PipeTransform } from "@angular/core"
import Position from "../models/Position"

@Pipe({
  name: "position"
})
export class PositionPipe implements PipeTransform {
    transform(value: Position[], args?: any): Position[] {
        // tslint:disable-next-line: triple-equals
        return value.filter(x => x.machine && x.machine.id == args.id)
    }
}

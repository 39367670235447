import { Component, Input, Output, EventEmitter } from "@angular/core"

@Component({
    selector: "app-toggle",
    templateUrl: "./toggle.component.html",
    styleUrls: ["./toggle.component.scss"]
})
export class ToggleComponent {
    @Input()
    class = "btn"

    @Input("true")
    trueClass = "btn-success"

    @Input("false")
    falseClass = "btn-danger"

    @Input()
    state: boolean

    @Output()
    stateChange = new EventEmitter<boolean>()

    constructor() {}

    toggle() {
        this.state = !this.state
        this.stateChange.emit(this.state)
    }
}

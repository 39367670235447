import { Injectable } from "@angular/core"

import * as io from "socket.io-client"
import { Subject, Subscription, BehaviorSubject } from "rxjs"
import config from "../config"
import { Observable } from "rxjs"
import { Router, NavigationEnd } from "@angular/router";
import { filter } from "rxjs/operators";
import { ObserveOnOperator } from "rxjs/internal/operators/observeOn";

@Injectable({
    providedIn: "root"
})
export class WebsocketService {

    private socket: SocketIOClient.Socket

    private routeSubscription: Subscription

    constructor(public router: Router) {
        this.connect()
    }

    connect() {
        if(this.socket)
            return;

        this.socket = io(/*config.backend*/)

        this.routeSubscription = this.router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe((url: NavigationEnd) => {
                const [, view, action, ...id] = url.urlAfterRedirects.split("/")
                this.socket.emit("route", {view, action, id})
            })
    }

    disconnect() {
        if(!this.socket)
            return

        this.routeSubscription.unsubscribe()
        this.socket.disconnect()
    }

    on(eventName: string): Subject<any> {
        return Subject.create({
            next: data => this.socket.emit(eventName, data)
        }, new Observable(subscriber => {
            const func = data => subscriber.next(data)
            this.socket.on(eventName, func)
            return () => this.socket.removeListener(eventName, func)
        }))
    }

    sender(eventName: string, data) {
        this.socket.emit(eventName, data)
    }
}

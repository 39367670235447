import { Injectable } from "@angular/core"
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router"
import { Observable } from "rxjs"

import { FormComponent } from "../components/_form.component"

@Injectable({
    providedIn: "root"
})
export class FormGuard implements CanDeactivate<FormComponent> {
    canDeactivate(component: FormComponent, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {

        return !component.hasUnsavedData() || confirm("You have unsaved changes. Do you really want to leave?");
    }
}